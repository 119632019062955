body{
  background-color: #B8E4F0;
  text-align: center; 
}
p{
  font-size: .9rem !important;
}
.navContainer{
  margin: 0px auto;
  max-width: 1000px;
  width: 100%;
}
.navbar.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  /* Additional styling */
}
.bg-dkblue{
  background-color: rgb(51, 39, 76);
}
.bg-dkpurple{
  background-color: rgb(62, 32, 110);
}
.txt-wht{
  color: white;
}
.navbar a{
  color: white;
}
.navbar a:hover{
  color: #93c7d5;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-toggler {
  border: 2px solid white;
}
.introWrapper{
  background-color:#7267CB;
  color: #B8E4F0;
}
.projectsWrapper{
  background-color: #B8E4F0;
  color: rgb(59 123 140);
}
.certsWrapper{
  background-color: #77a3ff;
  color: #494378;
}
.resumeWrapper{
  background-color: #7267CB;
  color: #B8E4F0;
}
.footerWrapper{
  background-color:rgb(62, 32, 110);
  color: white;
}
.introWrapper, .projectsWrapper, .certsWrapper, .resumeWrapper, .footerWrapper{
  padding: 10px 0px;
}
.underline{
  text-decoration: underline;
}
.borderBottom{
  border-bottom: 1px solid gray;
}
.separator{
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #b7e4f0;
  margin: 5px 0px;
}
.bold{
  font-weight: bold;
}
.resumeHeader{
  margin:5px 0px;
  font-size: 1.2rem;
  font-weight: bold;
}
.strengths li{
  text-align: left;
}
.technical p, .experienceItem p{
  text-align: left;
  margin: 0px;
  padding: 0px;
}
.experienceItem p{
  font-weight: bold;
}
.experienceItem li, .technical li{
  text-align: left;
}
.certSection{
  margin: 20px 0px;
  text-align: left;
}
.subtext{
  margin: 0px;
  padding: 0px;
  font-size: .7rem !important;
  color:#766cb9;
  font-weight: bold;
}
.certDownloadBtn{
  margin: 5px 0px;
  text-align: center;
}
.card{
  background-color: #c9f4ff;
  margin-bottom: 10px;
}
.card-body{
  color: #3b7b8c;
}
.cardTxtWrap{
  min-height: 228px;
}
.card-text{
  margin: 5px 0px;
  padding: 0px;
}
.card-title{
  margin: 0px;
  padding: 0px;
}
.projectLink{
  margin-top: 10px;
}
.cardImgWrapper{
  width: 100%;
  max-height:180px;
  overflow: hidden;
}
.monsterImgWrap{
  margin-bottom: 20px;
}
.carousel-control-next-icon{
  background-image: url(./assets/right-arrow.png);
  width: 19px;
}
.carousel-control-prev-icon {
  background-image: url(./assets/left-arrow.png);
  width: 19px;
}
.modal-body{
  font-size: .7rem;
}
@media (max-width: 405px) {
  .footerTxt{
    font-size: .7rem;
  }
}


